<template>
    <ui-create
        name="subGroup"
        :title="title"
        :load="load"
    >
        <template #body >

                <v-form ref="form" >
                    <v-row>
                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-text-field
                                label="Descrição"
                                v-model="form.descricao"
                                counter
                                maxlength="32"
                                :rules="[
                                    form_descricao_rules.required, form_descricao_rules.counter,
                                        form_descricao_rules.min
                                ]"
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="4"
                        >
                            <v-autocomplete
                                label="Grupo"
                                v-model="form.id_grupo"
                                :items="sections"
                                item-text="descricao"
                                item-value="id"
                                :rules="[form_grupo_rules.required]"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-checkbox
                                label="Ativo?"
                                v-model="form.ativo"
                                true-value="s"
                                false-value="n"
                            ></v-checkbox>

                            <v-checkbox
                                label="Ativo para mesas?"
                                v-model="form.ativo_web"
                                true-value="s"
                                false-value="n"
                            ></v-checkbox>

                            <v-checkbox
                                label="Ativo para garçons?"
                                v-model="form.ativo_mob"
                                true-value="s"
                                false-value="n"
                            ></v-checkbox>

                            <v-checkbox
                                label="Ativo para o Gestor?"
                                v-model="form.ativo_des"
                                true-value="s"
                                false-value="n"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
        </template>
    </ui-create>
</template>

<script>
import uiCreate from '@/templates/create'
export default {
    name: 'vwsubGroupCreate',
    components: {
        uiCreate
    },
    data(){
        return {
            load: false,
            form: {
                descricao: '',
                id_grupo: null,
                ativo: null,
                ativo_des: null,
                ativo_web: null,
                ativo_mob: null
            },

            sections: [],

            form_descricao_rules: {
                required: value => !!value || 'Descrição é obrigatória.',
                counter: value => value.length <= 32 || 'Dados inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },

            form_grupo_rules: {
                required: value => !!value || 'Grupo é obrigatório.'
            }
        }
    },

    computed:{
        id: function(){
            return this.$route.params.id
        },

        title(){
            return this.id ? `Editar Sub Grupo: ${this.id}` : 'Cadastrar Sub Grupo'
        }
    },

    methods: {
        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },
    
        save: function(){
            if(this.$refs.form.validate()){
                this.load = true
                if(this.id !== undefined){
                    this.update()
                }else{
                    this.create()
                }
            }
        },

        update: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/sub-group/update/' + this.id, {
                method: 'PUT',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.returnRead()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao atualizar o sub-grupo!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        create: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/sub-group/create', {
                method: 'POST',
                headers: new Headers({
                    'Authentication': this.$store.getters.token,
                    'Data': JSON.stringify(this.form)
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.alertSuccess = {
                        text: 'Sub Grupo cadastrado com sucesso.',
                        show: true,
                        type: 'red darken-1'
                    }
                    this.clearForm()
                })
                .catch(error => {
                    console.error(error)
                    this.alert = {
                        text: 'Erro interno ao gravar o sub-grupo!',
                        show: true,
                        type: 'red darken-1'
                    }
                })
                .finally(() => {
                    this.load = false
                })
        },

        getData: function(){
            this.load = true
            fetch(process.env.VUE_APP_API + '/sub-group/read/' + this.id, {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.form = resJSON.response[0].listhelper[0]
                })
                .catch(error => {
                    console.error(error)
                })
                .finally(() => {
                    this.load = false
                })
        },

        getSections: function(){
            fetch(process.env.VUE_APP_API + '/generics/description/grupos', {
                method: 'GET',
                headers: new Headers({
                    'Authentication': this.$store.getters.token
                })
            })
                .then(res => res.json())
                .then(resJSON => {
                    console.log(resJSON)
                    this.sections = resJSON.response
                })
                .catch(error => {
                    console.error(error)
                })
        },
    },

    mounted(){
        if (this.id != undefined) {
            this.getData()
        }

        this.getSections()

        this.$root.$on('save-changes-on-subGrupo', () => {
            this.$nextTick(() => {
                this.save()
            })
        })
    }
}
</script>